<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Agregar Paciente
            <template v-slot:actions>
                <v-icon color="primary" @click.native.stop="reloadView++">
                    mdi-refresh
                </v-icon>
                <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                    mdi-close
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadView">
            
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        Agregar Paciente
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card  background-color="transparent" >
                            <v-card-text>
                                <manage-add-veterinary-patient :win="win"></manage-add-veterinary-patient>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
          
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import ManageAddVeterinaryPatient from './partial/ManageAddVeterinaryPatient';

    export default {
        name: "VeterinaryPatientAdd",
        props: ['win'],
        data() {
            return {
                tab: null,
                reloadView: 0
            }
        },
        components: {
            //ManageVeterinaryPatient
            ManageAddVeterinaryPatient
        },
        mounted() {
        },
        methods: {
            /*reloadData(payload) {
                this.$emit('reloadData', payload)
            }*/
        }

    }
</script>

<style scoped>

</style>
