<template>
  <div style="background-color: white !important; border-radius: 15px; padding: 20px">
  <v-form v-model="valid" class="position-relative">
    <block v-if="loading"></block>
    
        <v-row >
        <v-col cols="12" class="tabsNh" style="padding: 14px;">
         
          <v-col  style="display: flex; justify-content: end; min-height: 50px">
                    <div>
                      <v-btn small class="btnsave" dark color="btnsave darken-1" @click.prevent.stop="addPatient"  v-if="$store.getters['rolesUser/getCustomRole'](21)">
                    
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                      </div>
                      </v-col>
                  

              
             
          
              </v-col>
            </v-row>

              
        <v-row>
              <v-col cols="12" md="6" style="display: flex; justify-content: space-between; margin-top: 10px;">
                <h2>Ficha de Paciente</h2>
                <br>
               <!--  <v-checkbox v-model="form.live" color="primary"
                  :label="`Condicion : ${!form.live ? 'Vivo' : 'Fallecido'}`"></v-checkbox> -->
                </v-col>
                <v-col cols="12" md="3" style="display: flex; justify-content: space-between;">
                <v-checkbox v-model="form.status" color="primary"
              :label="`Estado : ${form.status ? 'activo' : 'inactivo'}`"></v-checkbox>
            </v-col>
                  <v-col cols="12" md="3" style="display: flex; justify-content: space-between;">
                <!-- <v-checkbox v-model="form.mode" color="primary"
                  :label="`Modo : ${form.mode ? 'Completo' : 'Basico'}`"></v-checkbox> -->
              </v-col>
            </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <div style="display: flex; justify-content: center; width: 80%;margin-left: 10%;">
              <v-img :src="url" class="preview-avatar"></v-img>
            </div>
            
            <v-file-input @change="Preview_image" v-model="image" label="Buscar" prepend-icon="mdi-camera"
              placeholder="Buscar" :rules="[maxFileSize]">
            </v-file-input>

            <div class="patientdiv">
                <div style="text-align: left; color: black;" class="ml-2 mt-1">
                  <v-checkbox v-model="form.status" color="primary"
                    :label="`Estado : ${form.status ? 'activo' : 'inactivo'}`"></v-checkbox>
                </div>

                <div style="text-align: left; color: black;" class="ml-2 mb-2" v-if="customer">
                  <b>ID Cliente: </b>
                  {{ customer.ID_Customer }}
                  <div v-if="customer">
                    <b>Cliente: </b>
                    {{ customer.Is_a_Business ? customer.BusinessName : customer.FirstName + " " +
                      customer.LastName }}
                  </div>
                  <div style="display: flex; justify-content: center;">
                    <v-btn class="my-4 btnclean" @click.prevent="showCustomer()">
                      Ver Cliente
                    </v-btn>
                  </div>
                </div>
              </div>
           

          

          </v-col>

          <v-col cols="12" md="9">
            <br/>
          
            <v-row>
             

              <v-col cols="12" md="4">
                <v-text-field outlined dense v-model="form.namepatient" :rules="nameRules" label="Nombre del paciente*"
                  placeholder="Escribe el nombre del paciente" required
                  @input="uppercaseInput('namepatient')"></v-text-field>
              </v-col>

              

              <!-- <v-col cols="12" md="4">
                <v-text-field outlined dense v-model="form.weight" label="Peso actual en kg"
                  placeholder="Escribe el peso"></v-text-field>
              </v-col> -->

              <v-col cols="12" md="2">
                <v-select outlined dense  attach v-model="form.gender" :items="['Hembra', 'Macho', 'Indefinido']" label="Género"></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field outlined dense v-model="form.color" label="Color" placeholder="Escribe el color"
                  @input="uppercaseInput('color')"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select outlined dense  attach v-model="form.species" :items="SpeciesList"
                item-text="name" item-value="name"
                label="Especies*" :rules="nameRules"></v-select>
                <!--v-text-field v-model="form.species" label="Especies*" placeholder="Escribe la especie" :rules="nameRules"
                  required></v-text-field-->
              </v-col>
              <v-col cols="12" md="4">
                <template v-if="speciesValues == null || speciesValues == []">
                  <v-text-field outlined dense  v-model="form.breed" label="Raza" placeholder="Escribe la raza"></v-text-field>
                </template>
                <template v-else>
                  <v-combobox 
                    outlined 
                    dense  
                    attach  
                    v-model="form.breed" 
                    :items="speciesValues" 
                    label="Raza" 
                    placeholder="Selecciona la raza"
                    ref="breedInput"
                    >
                  </v-combobox>
                </template>
               
              </v-col>
             





              <!--   <v-col cols="12" md="4">
                  <v-text-field outlined dense v-model="form.condition" label="Condición"
                    placeholder="Escribe la condición"></v-text-field>
                </v-col> -->

              <!-- <v-col cols="12" md="4">
                  <v-text-field outlined dense v-model="form.visitreason" label="Razón de la visita"
                    placeholder="Escribe el motivo"></v-text-field>
                </v-col> -->
              <!-- <v-col cols="12" md="4">
                  <v-text-field outlined dense v-model="form.visitdata" label="INFORMACIÓN DE VISITA"
                    placeholder="Escribe la información de visita"></v-text-field>
                </v-col> -->
             


              <v-col cols="12" md="4">
                <v-text-field outlined dense v-model="form.pedigreename" label="Nombre según Pedigree"
                  placeholder="Escribe el nombre según Pedigree"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field outlined dense v-model="form.tattoo" label="Tatuaje"
                  placeholder="Escribe si tiene manchas o tatuajes en su pelaje"></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field outlined dense v-model="form.registrynumber" label="Número de registro"
                  placeholder="Escribe el número de registro"></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field outlined dense v-model="form.microchip" label="MicroChip"
                  placeholder="Escribe el número de MicroChip"></v-text-field>
              </v-col>
              <!-- <template v-if="form.mode == true">
              </template> -->

              <v-col cols="12" md="3">
                <date-picker label="Fecha de Nacimiento" @dateSelected="(param) => { date = param }" :value="date"
                  v-model="date"></date-picker>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field outlined dense @keyup="calculateDOBwithAge()" v-model="form.years" type="number" label="Años"
                  placeholder="Escribe los años"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field outlined dense v-model="form.oldid" label="Código alterno"
                  placeholder="Escriba el Código alterno"></v-text-field>
              </v-col> 

              <v-col cols="12" class="mb-4">
                <h4>Informacion de Contacto</h4>
              </v-col>
              
              <v-col cols="12" md="6">
                <v-text-field outlined dense v-model="form.notificationemail" label="Notificación de correo"
                  placeholder="escribe el correo"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field outlined dense v-model="form.notificationmobile" label="Notificación celular"
                  placeholder="Escribe el celular"></v-text-field>
              </v-col>

              <v-col cols="12" md="12"></v-col>

              <v-col cols="12" md="6" lg="3">
                <v-checkbox v-model="form.sendemail" label="Enviar mensaje"></v-checkbox>
              </v-col>
              <v-col cols="12" md="6" lg="3" style="display: none">
                <v-checkbox v-model="form.sendsms" label="Enviar SMS"></v-checkbox>
              </v-col>
              <v-col cols="12" md="6" lg="3" style="display: none">
                <v-checkbox v-model="form.sendwhatsapp" label="Enviar Whatsapp"></v-checkbox>
              </v-col>
              <v-col cols="12" md="6" lg="3" style="display: none">
                <v-checkbox v-model="form.sendpushnotification" label="Enviar notificación Push"></v-checkbox>
              </v-col>
              <v-col cols="12" >
                <h3  style="text-align: center;" :class="showBgcolorNotes"  @click.prevent="showNotes = !showNotes" >Comentarios
                  <!--v-icon v-if="showNotes" color="white" left>
                        mdi-minus
                      </v-icon>
                      <v-icon v-else color="white" left> mdi-plus </v-icon-->
                      </h3>
                      <br/>
                      <h3  style="text-align: center;" :class="showBgcolorAlergias"  @click.prevent="showAlergias = !showAlergias" >Alergias</h3>
                <v-dialog
    width="60%"
    v-model="showNotes"

    >
      <v-card class="pa-4">
        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
            <v-btn small class="btndelete" @click.prevent="showNotes = false" >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
                    <quill-editor
                    :options="quillOptions"
                    v-show="showNotes"
                    v-model="form.comment" @change="onEditorChange($event)"></quill-editor>
                  </v-card>
                </v-dialog>

                <v-dialog width="60%" v-model="showAlergias">
                  <v-card class="pa-4">
                    <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                      <v-btn small class="btndelete" @click.prevent="showAlergias = false" >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <quill-editor :options="quillOptions" v-show="showAlergias" 
                    v-model="form.alergias" @change="onEditorChange2($event)"></quill-editor>
                  </v-card>
                </v-dialog>
              </v-col>


              

            </v-row>
          </v-col>
          <v-col class="mt-4" cols="12"></v-col>

        </v-row>
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
        
      </v-form>
    </div>
</template>

<script>


import Vue from "vue";
import Block from "@/components/Block";
import DatePicker from "../../../components/DatePicker.vue";
import Alerts from "../../../components/Alerts.vue";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapState } from "vuex";

export default {
  name: "ManageAddVeterinaryPatient",
  components: { DatePicker, Block, Alerts },
  props: ['win'],
  data: () => ({
    valid: false,
    nameRules: [
      v => !!v || 'El campo es requerido',
    ],
    customer: null,
    date: null,
    menu: false,
    quillOptions: quillConfig,
    showBgcolorNotes: "tabs",
    showBgcolorAlergias: "tabs",
    showNotes: false,
    showAlergias: false,
    modal: false,
    menu2: false,
    url: null,
    image: null,
    form: {
      mode: false,
    },
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    loading: false,
    id_user_login: null,
    SpeciesList: [],
    dataCreated: null,
    validImageSize: true,
  }),
  watch: {

    date: {
      handler: function () {
        this.form.dateofbirth = this.date;
        this.calculateAgeWithDOB();
      },
      immediate: true,
    }

  },
  mounted() {
    this.customer = this.win.meta.customerNew ? this.win.meta.customerNew : this.win.meta.customerToShow;
   
    if(this.win.meta.customerToShow.ID_Customer){
      Vue.set(this.form, 'customer', this.win.meta.customerToShow.ID_Customer)
    }
    else{
      Vue.set(this.form, 'customer', this.customer.ID_Customer)
    }
    
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.form.idbusiness = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.form.idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.form.status = 1
   
    this.defaultValues();

  },
  computed: {
    ...mapState("sysparameters", ["parameters"]),
    speciesValues() {
                let values = null;
                if(this.form.species == null)
                    return []
                this.SpeciesList.forEach((item) => {
                    if(item.name == this.form.species)
                        values = item.value
                })
                if(typeof values == 'object')
                    return values
               
                if(values != null)
                    return values.split(',')
                else
                    return []
            },
  },
  methods: {
    uppercaseInput(fieldName) {
      this.form[fieldName] = this.form[fieldName].toUpperCase();
    },
    async getDataNew(){
      const customer = await this.$API.customers.getOneCustomer(this.dataCreated.ID_Customer);

      this.dataCreated.customer = customer;


       this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
       this.$store.dispatch('addWindow', {
                    name: 'veterinaryPatientData',
                    component: 'VeterinaryPatientData',
                    unique: false,
                    meta: {veterinarypatients: this.dataCreated, reload:true }
                })
      /*  this.$store.dispatch("addWindow", {
          name: "patientList",
          component: "PatientsList",
          unique: true,
        });
        this.$store.dispatch("reloadData", "patients"); */

        
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "redirectoPatient") {
        this.getDataNew();
        return;
      }

      
      if (this.alert.options == "redirectcustomer") {
        if(this.win.meta.customerToShow.ID_Customer){
          this.$store.dispatch('reloadData', 'CustomerData')
          this.$store.commit('customerData/setReloaders', 'reloadpatientofcustomer')
          this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid }).then(() =>{
            this.$store.dispatch('addWindow', {
                'name': 'customerData',
                component: 'CustomerData',
                unique: true,
                meta: { customerToShow: this.win.meta.customerToShow }
            })
          })
        }
        else{
          this.$store.dispatch('reloadData', 'CustomerData')
          this.$store.commit('customerData/setReloaders', 'reloadpatientofcustomer')
          this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid }).then(() =>{
            this.$store.dispatch('addWindow', {
                'name': 'customerData',
                component: 'CustomerData',
                unique: true,
                meta: { customerToShow: this.customer }
            })
          })
        }
        
        /*this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("addWindow", {
          name: "customerList",
          component: "CustomersList",
          unique: true,
        });
        this.$store.dispatch("reloadData", "customers");*/
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletecustomer") {
        this.deleteCustomer();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    defaultValues() {

    

      if (this.win.meta.customerToShow) {
    
        if(this.win.meta.customerToShow.Is_a_Business){
        
          this.form.notificationemail = this.win.meta.customerToShow.BusinessContactEmail ? this.win.meta.customerToShow.BusinessEmail : this.win.meta.customerToShow.businessemail
          this.form.notificationmobile = this.win.meta.customerToShow.BusinessPhone ? this.win.meta.customerToShow.BusinessPhone : this.win.meta.customerToShow.businessphone
          this.form.sendemail = this.win.meta.customerToShow.SendEmail_BusinessEmail;
          this.form.sendsms = this.win.meta.customerToShow.SendSMS_BusinessMobile;
          this.form.sendwhatsapp = this.win.meta.customerToShow.SendWhatsapp_BusinessMobile;
          this.form.sendpushnotification = this.win.meta.customerToShow.SendPushUp;
          
        }
        else{
         
          this.form.notificationemail = this.win.meta.customerToShow.email ? this.win.meta.customerToShow.email : this.win.meta.customerToShow.Email
          this.form.notificationmobile = this.win.meta.customerToShow.PhoneNumber ? this.win.meta.customerToShow.PhoneNumber : this.win.meta.customerToShow.phonenumber
          this.form.sendemail = this.win.meta.customerToShow.SendEmail ? this.win.meta.customerToShow.SendEmail : this.win.meta.customerToShow.SendEmail;
          this.form.sendsms = this.win.meta.customerToShow.SendSMS;
          this.form.sendwhatsapp = this.win.meta.customerToShow.SendWhatsapp;
          this.form.sendpushnotification = this.win.meta.customerToShow.SendPushUp;
        }
      }
      if(this.parameters){
       
      
       this.parameters.forEach((item) => {
         
               if(item.ParameterType == "ModePatient")
                   this.form.mode = item.ParameterValue  
               if(item.ParameterType == "SpeciesList")
                   this.SpeciesList = item.ParameterValue
              
           }) 
     }
    },

    calculateDOBwithAge() {
      var dateNow = new Date();
      var years = dateNow.getFullYear();
      years = years - this.form.years;
      var dateDOB = dateNow.getDate() + "/" + (dateNow.getMonth() + 1) + "/" + years;
      this.form.dayofbirth = dateDOB;

      this.date = dateDOB;

    },
    calculateAgeWithDOB() {
      var dateNow = new Date();

      var date01 = this.form.dateofbirth;



      var date02 =
        dateNow.getDate() +
        "/" +
        (dateNow.getMonth() + 1) +
        "/" +
        dateNow.getFullYear();



      if (date01 != null && date02 != null) {
        var aDate01 = date01.split("/");
        var aDate02 = date02.split("/");

        var fDate01 = Date.UTC(aDate01[2], aDate01[1] - 1, aDate01[0]);
        var fDate02 = Date.UTC(aDate02[2], aDate02[1] - 1, aDate02[0]);

        var dif = fDate02 - fDate01;
        var age = Math.floor(dif / (1000 * 60 * 60 * 24) / 365);

        this.form.years = age;

      }
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image)
    },

    onEditorChange(e) {
      this.form.comment = e.html;
       if (this.form.comment != "" ) {
      
        this.showBgcolorNotes = "tabsContent";
      }
      else if (this.form.comment == "" || this.form.comment == "<p><br></p>" ) {
       

        this.showBgcolorNotes = "tabs"
      }
      else{
       
        this.showBgcolorNotes = "tabs"
      } 
    },

    onEditorChange2(e) {
      this.form.alergias = e.html;
       if (this.form.alergias != "" && this.form.alergias != null) {
      
        this.showBgcolorAlergias = "tabsContent";
      }
      else if (this.form.alergias == "" || this.form.alergias == "<p><br></p>" || this.form.alergias == null) {
       

        this.showBgcolorAlergias = "tabs"
      }
      else{
       
        this.showBgcolorAlergias = "tabs"
      } 
    },

    showCustomer() {
      this.$store.dispatch('deleteWindowByUuid', {uuid: this.win.uuid}).then(() =>{
        this.$store.dispatch('reloadData', 'CustomerData')
        this.$store.dispatch('addWindow', {
          'name': 'customerData',
          component: 'CustomerData',
          unique: true,
          meta: { customerToShow: this.win.meta.customerToShow }
        })
      })
    },

    addPatient() {
      if( !(this.speciesValues == null || this.speciesValues == []) ) {
        this.$refs["breedInput"].blur();
      }
      this.$nextTick(() => {
        if (this.checkImageSize()) return;

        console.log("click")
        if (this.valid) {
          this.loading = true;
          const formdata = new FormData();
          Vue.set(this.form, "id_user_insert", this.id_user_login);
          formdata.append('file', this.image);
          formdata.append('veterinarypatient', JSON.stringify(this.form));

          this.$API.veterinarypatients.addPatient(formdata)
            .then((res) => {
              console.log('recien creado',res)
              this.showAlert(
                "success",
                "Muy Bien",
                "Se agrego correctamente el paciente",
                "redirectoPatient"
              );
              this.loading = false;
              this.dataCreated = res;
              /*   this.$toasted.success(response); */

              /*this.$store.dispatch('reloadData', 'CustomerData')
              this.$store.dispatch('addWindow', {
                'name': 'customerData',
                component: 'CustomerData',
                unique: true,
                meta: { customerToShow: this.win.meta.customerToShow }
              })
              console.log(response);

              this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid })*/

            }).catch(() => {
              this.loading = false
            })
        }
      });
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
  }
}
</script>

<style scoped>

.btnsave {
  background-color: #00A178 !important;
  color: white;
}
.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.tabsContent:hover {
  background-color:  #FFB266;
  cursor: pointer;
}

.tabs{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}
.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}


.patientdiv {
  border: 0.5px solid gray;
  box-shadow: 2px 2px 2px 2px gray;
  border-radius: 3%;
  text-align: center;
}
</style>
